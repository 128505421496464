import react from 'react';
import './card.css';

const Card = ({ name, image, link }) => {
return (
  <div className="card-container">
    <a href={link} target="_blank" rel="noreferrer">
      <img className="card-image" alt="card img" src={image}></img>
    </a>
    <h2 className="card-title">
      <a href={link} target="_blank" rel="noreferrer">
        {name}
      </a>
    </h2>
    <h4 className="card-link">
      <a href={link} target="_blank" rel="noreferrer">
        - View Live Site -
      </a>
    </h4>
  </div>
);
}

export default Card;
