const projects = [
  {
    name: "Dani Louis Designs",
    link: "https://www.houzz.com/website-services/sites/14284/view",
    image: "https://pace-pusher.s3-us-west-1.amazonaws.com/dani.png",
  },
  {
    name: "Iris Kaplow Lanscapes",
    link: "https://www.houzz.com/website-services/sites/15277/view/",
    image: "https://pace-pusher.s3-us-west-1.amazonaws.com/nyc.png",
  },
  {
    name: "Ultimate Water Creations",
    link: "https://ultimatewatercreations.net/",
    image:
      "https://pace-pusher.s3-us-west-1.amazonaws.com/ultimatewatercreations.png",
  },
  {
    name: "Jenny Guza Designs",
    link: "https://jennyguzadesigns.com/",
    image: "https://pace-pusher.s3-us-west-1.amazonaws.com/jenny-2.png",
  },
];

export default projects;
