import './App.css';
import Card from './components/card';
import projects from './components/projectData';
import dani from './images/dani.png';
import jenny from './images/jenny.png';
import nyc from './images/nyc.png';
import ultimatewatercreations from './images/ultimatewatercreations.png'

function App() {
  return (
    <>
    <div className="hero-section">
      <div className="top">
        <h1> Houzz Sites</h1>
        <p>-a colletion of published websites created by myself- </p>
      </div>
      <div className='background'></div>
    </div>
     <div className='content-container'>
        {projects.map(({name, image, link}) => <Card name ={name} image={image} link={link}/>)}
      </div>
      </>
  );
}

export default App;
